import React, { Component } from 'react';
import { Route, Link } from "react-router-dom";

import LOGO from '../../images/logo.png';

import IMAGE0 from '../../images/minimalist/minimalist0.jpg';
import IMAGE1 from '../../images/minimalist/minimalist1.jpg';
import IMAGE2 from '../../images/minimalist/minimalist2.jpg';
import IMAGE3 from '../../images/minimalist/minimalist3.jpg';
import IMAGE4 from '../../images/minimalist/minimalist4.jpg';
import IMAGE5 from '../../images/minimalist/minimalist5.jpg';
import IMAGE6 from '../../images/minimalist/minimalist6.jpg';
import IMAGE7 from '../../images/minimalist/minimalist7.jpg';
import IMAGE8 from '../../images/minimalist/minimalist8.jpg';
import IMAGE9 from '../../images/minimalist/minimalist9.jpg';
import IMAGE10 from '../../images/minimalist/minimalist10.jpg';
import IMAGE11 from '../../images/minimalist/minimalist11.jpg';

const arrayImages = [
  IMAGE0,
  IMAGE1,
  IMAGE2,
  IMAGE3,
  IMAGE4,
  IMAGE5,
  IMAGE6,
  IMAGE7,
  IMAGE8,
  IMAGE9,
  IMAGE10,
  IMAGE11
]

var imageInterval;

class Minimalist extends Component {
  constructor(props){
    super(props);
    this.state = {
      showImage: 0
    }

    this.setSliderImage = this.setSliderImage.bind(this);
  }
  componentWillMount(){
    this.props.showMenuRight(true);
  }
  componentDidMount(){
    imageInterval = setInterval(this.setSliderImage, 3000);
  }
  componentWillUnmount(){
    clearInterval(imageInterval);
  }
  setSliderImage(){
    const state = this.state;
    if(state.showImage < arrayImages.length - 1) this.setState({showImage: state.showImage + 1});
    else this.setState({showImage: 0});
  }
  render(){
    const props = this.props;
    const state = this.state;
    const client = props.client;
    const headerType = client.headerType;

    var images = map(arrayImages, (val, key) => {
      return <div
        className={`sliderImage ${state.showImage === key ? 'show' : ''}`}
        style={{
          backgroundImage: `url(${val})`
        }}
        key={key}
      ></div>
    });

    return(
      <div
        id="Minimalist"
        className={headerType}
      >
        <div className="mainContent">
          {
          headerType === 'phone' && <div className="headerContent">
            <div className="logo">
              <Link to='/'>
                <img src={LOGO} />
              </Link>
            </div>
            <div className="sectionName">MINIMALISTA</div>
          </div>
          }
          <div className="sliderContent">
            {images}
          </div>
          <div className="descriptionContent">
            <div className="descriptionText">
              <div className="column">
                <div className="paragraph">
                   Tenemos para ti un lugar moderno, integrado por espacios muy
                   versátiles que pueden adaptarse de acuerdo con tus gustos y necesidades,
                   para ofrecerte el ambiente perfecto para tus eventos sociales y empresariales.
                </div>
                <div className="paragraph">
                   Nuestras instalaciones cuentan con áreas dinámicas para poder crear una atmósfera
                   moderna y elegante en nuestro magnífico salón minimalista,
                   en el jardín o en el salón de fiestas infantiles.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Minimalist;
