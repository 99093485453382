import React, { Component } from 'react';
import { Route, Link } from "react-router-dom";

import LOGO from '../../images/logo.png';

import IMAGE1 from '../../images/services/services1.jpg';
import IMAGE2 from '../../images/services/services2.jpg';
import IMAGE3 from '../../images/services/services3.jpg';
import IMAGE4 from '../../images/services/services4.jpg';
import IMAGE5 from '../../images/services/services5.jpg';
import IMAGE6 from '../../images/services/services6.jpg';
import IMAGE7 from '../../images/services/services7.jpg';

const arrayImages = [
  IMAGE1,
  IMAGE2,
  IMAGE3,
  IMAGE4,
  IMAGE5,
  IMAGE6,
  IMAGE7
]

var imageInterval;

class Services extends Component {
  constructor(props){
    super(props);
    this.state = {
      showImage: 0
    }

    this.setSliderImage = this.setSliderImage.bind(this);
  }
  componentWillMount(){
    this.props.showMenuRight(true);
  }
  componentDidMount(){
    imageInterval = setInterval(this.setSliderImage, 3000);
  }
  componentWillUnmount(){
    clearInterval(imageInterval);
  }
  setSliderImage(){
    const state = this.state;
    if(state.showImage < arrayImages.length - 1) this.setState({showImage: state.showImage + 1});
    else this.setState({showImage: 0});
  }
  render(){
    const props = this.props;
    const state = this.state;
    const client = props.client;
    const headerType = client.headerType;

    var images = map(arrayImages, (val, key) => {
      return <div
        className={`sliderImage ${state.showImage === key ? 'show' : ''}`}
        style={{
          backgroundImage: `url(${val})`
        }}
        key={key}
      ></div>
    });

    return(
      <div
        id="Services"
        className={headerType}
      >
        <div className="mainContent">
          {
          headerType === 'phone' && <div className="headerContent">
            <div className="logo">
              <Link to='/'>
                <img src={LOGO} />
              </Link>
            </div>
            <div className="sectionName">SERVICIOS</div>
          </div>
          }
          <div className="sliderContent">
            {images}
          </div>
          <div className="descriptionContent">
            <div className="descriptionText">
              <div className="oneColumnText">
                En Festeggia Esmeralda cada evento es único y logramos que lo vivas, lo sientas y lo
                celebres como único e irrepetible.
                Hacemos que tus ideas y tus ilusiones de tu evento se hagan realidad,
                para que siempre lo recuerdes como un momento fantástico de tu vida.
              </div>
              <div className="twoColumnsText">
                <div className="column">
                  <div className="paragraph">
                     Como parte de nuestros servicios ofrecemos:
                     <ul>
                       <li>– Sacramentos</li>
                       <li>– Decoración y ambientación especial</li>
                       <li>– Servicios de chimenea y calentadores</li>
                       <li>– Comida tipo buffet, finger food, internacional</li>
                       <li>– Arreglos florales finos y temáticos</li>
                       <li>– Grupos versátiles DJ's</li>
                       <li>– Figuras de hielo</li>
                     </ul>
                  </div>
                </div>
                <div className="column last">
                  <div className="paragraph last">
                    <ul>
                      <li>– Mantelería de alta costura</li>
                      <li>– Servicio de fotografía y video</li>
                      <li>– Vajilla de línea</li>
                      <li>– Pirotecnia</li>
                      <li>– Hookas y narguilas</li>
                      <li>– Servicio de Valet Parking</li>
                      <li>– Y mucho más...</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
