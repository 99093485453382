var st = adminCookie.get('st');
var url = 'http://intranettmb.terramarbrands.com.mx/modulesphp/login_module.php?option=3&token=' + st;

global.clearSession = () => {
  document.cookie = 'st=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;';
  document.cookie = 'kitadmin=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;';
  document.cookie = 'kitnom=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;';
  document.cookie = 'kitapa=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;';
  document.cookie = 'kitmail=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;';
  /*
  if(window.location.host === 'localhost:3000'){
    window.location.replace("http://localhost:3000");
  }else{
    window.location.replace("http://carrito.terramarbrands.com.mx");
  }
  */
};
/*
if(st){
  http()
  .method('GET')
  .url(url)
  .on('success', function(data){
    if(!data[0]){
      clearSession();
    }
  })
  .go();
}
*/
