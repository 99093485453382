import React, { Component } from "react";
import { Route, Link } from "react-router-dom";

import LOGO from "../../images/logo.png";

import IMAGE1 from "../../images/about/about1.JPG";
import IMAGE2 from "../../images/about/about2.JPG";
import IMAGE3 from "../../images/about/about3.JPG";
import IMAGE4 from "../../images/about/about4.JPG";
import IMAGE5 from "../../images/about/about5.JPG";
import IMAGE6 from "../../images/about/about6.jpg";

const arrayImages = [
  IMAGE1,
  IMAGE2,
  IMAGE3,
  //IMAGE4,
  IMAGE5,
  IMAGE6
];

var imageInterval;

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImage: 0
    };

    this.setSliderImage = this.setSliderImage.bind(this);
  }
  componentWillMount() {
    this.props.showMenuRight(true);
  }
  componentDidMount() {
    imageInterval = setInterval(this.setSliderImage, 3000);
  }
  componentWillUnmount() {
    clearInterval(imageInterval);
  }
  setSliderImage() {
    const state = this.state;
    if (state.showImage < arrayImages.length - 1)
      this.setState({ showImage: state.showImage + 1 });
    else this.setState({ showImage: 0 });
  }
  render() {
    const props = this.props;
    const state = this.state;
    const client = props.client;
    const headerType = client.headerType;

    var images = map(arrayImages, (val, key) => {
      return (
        <div
          className={`sliderImage ${state.showImage === key ? "show" : ""}`}
          style={{
            backgroundImage: `url(${val})`
          }}
          key={key}
        ></div>
      );
    });

    return (
      <div id="About" className={headerType}>
        <div className="mainContent">
          {headerType === "phone" && (
            <div className="headerContent">
              <div className="logo">
                <Link to="/">
                  <img src={LOGO} />
                </Link>
              </div>
              <div className="sectionName">NOSOTROS</div>
            </div>
          )}
          <div className="sliderContent">{images}</div>
          <div className="descriptionContent">
            <div className="descriptionText">
              <div className="column">
                <div className="paragraph">
                  Tenemos para ti un lugar moderno, integrado por espacios muy
                  versátiles que pueden adaptarse de acuerdo con tus gustos y
                  necesidades, para ofrecerte el ambiente perfecto para tus
                  eventos sociales y empresariales.
                </div>
                <div className="paragraph">
                  Nuestras instalaciones cuentan con áreas dinámicas para poder
                  crear una atmósfera moderna y elegante en nuestro magnífico
                  salón minimalista, en el jardín o en el salón de fiestas
                  infantiles.
                </div>
              </div>
              <div className="column column2">
                <div className="paragraph">
                  Ven y disfruta de nuestro salón de fiestas estilo minimalista
                  y vive una inolvidable experiencia.
                </div>
                <div className="paragraph">
                  Todos estos espacios están equipados con mobiliario de estilo
                  vanguardista y cuentan con una exquisita iluminación que
                  inundará su evento de un ambiente íntimo y acogedor.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
