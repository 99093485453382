import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";

import galleryItems from './galleryObject.js';

var galleryItemsLoaded;

export default class Gallery extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeIndex: 0,
      nav1: null,
      nav2: null,
    }
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goTo = this.goTo.bind(this);
  }
  next() {
    this.slider1.slickNext();
  }
  previous() {
    this.slider1.slickPrev();
  }
  goTo(index, slider){
    const state = this.state;

    if(slider === 'slider2') this.slider2.slickGoTo(index);
    else this.slider1.slickGoTo(index);

    this.setState({activeIndex: index});
  }
  componentWillMount(){
    this.props.showMenuRight(true);
    galleryItemsLoaded = galleryItems;
  }
  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }
  render(){
    const props = this.props;
    const state = this.state;
    const client = props.client;
    const headerType = client.headerType;

    var settings = {
      arrows: false,
      //fade: true,
      autoplay: true,
      swipeToSlide: true,
      speed: 500,
      ref: slider => (this.slider1 = slider),
      beforeChange: (oldIndex, newIndex) => {
        this.goTo(newIndex, 'slider2');
      },
    };

    var settingsNav = {
      ref: slider => (this.slider2 = slider),
      arrows: false,
      slidesToShow: headerType === 'phone' ? 3 : headerType === 'tablet' ? 5 : 7,
      swipeToSlide: true,
      focusOnSelect: true,
      centerMode: true,
      beforeChange: (oldIndex, newIndex) => {
        this.goTo(newIndex);
      },
    }

    var sliderItems = map(galleryItemsLoaded, (val, key) => {
      return (
        <div key={key}>
          <div
            className="sliderItem"
            style={{
              height: headerType === 'phone' ? 'calc(100vh - 50px)' : 'calc(100vh - 127px)',
              backgroundImage: `url(${val.src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
          >
          {
            /*
            <img style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                display: 'block'
              }} src={val.src}></img>
            */
          }
          </div>
        </div>
      );
    });

    var navItems = map(galleryItems, (val, key) => {
      return (
        <div key={key}>
          <div
            className="navItem"
          >
            <div
              className="imageContent"
              style={{
                margin: '0 4px',
                height: '80px',
                border: `2px solid ${key === state.activeIndex ? '#41727c' : 'rgba(255, 255, 255, 0)'}`,
                backgroundImage: `url(${val.src})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                cusros: 'pointer'
              }}
            >
            </div>
          </div>
        </div>
      );
    });

    var next = <div
      className="arrow next"
      onClick={this.next}
    >
      <i className="material-icons">navigate_next</i>
    </div>

    var prev = <div
      className="arrow prev"
      onClick={this.previous}
    >
      <i className="material-icons">navigate_before</i>
    </div>

    return(
      <div
        id="Gallery"
        className={headerType}
      >
        <div className="mainContent">
          <div className="sliderContent">
            <Slider {...settings}>
              {sliderItems}
            </Slider>
            {prev}
            {next}
          </div>
          <div className="navContent">
            <Slider {...settingsNav}>
              {navItems}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
