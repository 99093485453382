import { EventEmitter } from 'events';
var dispatcher = require('../dispatcher');

const CHANGE_EVENT = 'change';

var path = '/';

class MasterStoreClass extends EventEmitter {

  client() { return client }
  path() { return path }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

}

const masterStore = new MasterStoreClass();

masterStore.dispatchToken = dispatcher.register(function(action) {
	var fx = {
		CHANGE_LOCATION: function(){
			path = action.path;
		}
	};

	if(fx[action.type]){
		var f = fx[action.type]();
		if(f === 'fxBreak') return;
		masterStore.emitChange();
	}
});

export default masterStore;
