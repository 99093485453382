'use strict';

var agent = (function(){
	var u = navigator.userAgent;
	var b = u.match(/Android/i) || u.match(/webOS/i) || u.match(/iPhone/i) || u.match(/iPad/i) || u.match(/iPod/i) || u.match(/BlackBerry/i) || u.match(/Windows Phone/i);
	if(b) return true;
	else return false;
})();

var device = (function(){
	if( agent ){
		var width;
		if( window.matchMedia('(orientation: portrait)').matches ){
			width = window.innerWidth;
			if(os.os === 'iOS') width = screen.width;
		}
		else{
			width = window.innerHeight;
			if(os.os === 'iOS') width = screen.height;
		}

		if( width < 670 ) return 'phone';
		else return 'tablet';
	}
	else{
		if(window.innerWidth > 1200) return 'desktop';
		else if( window.innerWidth < 670 ) return 'phone';
		else return 'tablet';
	}
})();

var data = {
	isTouch:(function(){
		var b = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
		if (b) return true;
		else return false;
	})(),
	isMobileAgent: agent,
	isIE: (function(){
	  var myNav = navigator.userAgent.toLowerCase();
	  return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
	})(),
	device: device,
	size: window.innerWidth < 1000 ? 'dim' : 'agm',
	headerType: device,
	isOrientationCapable:(function(){
		if( typeof window.matchMedia === 'undefined' ) return false;
		else return true;
	})(),
	orientation:(function(){
		if( typeof window.matchMedia === 'undefined') return 'NOT_COMPATIBLE';
		else{
			if( window.matchMedia('(orientation: portrait)').matches ) return 'portrait';
			else return 'landscape';
		}
	})(),
	browser: os.browser,
	os: os.os,
	version: os.version
};

global.client = data;
