const options = [
  {
    text: 'NOSOTROS',
    href: '/nosotros',
    color: '#b1122f'
  },
  {
    text: 'MINIMALISTA',
    href: '/minimalista',
    color: '#dba727'
  },
  {
    text: 'INFANTIL',
    href: '/infantil',
    color: '#80b1b0'
  },
  {
    text: 'SERVICIOS',
    href: '/servicios',
    color: '#b1122f'
  },
  {
    text: 'BANQUETES',
    href: '/banquetes',
    color: '#8d5f22'
  },
  {
    text: 'GALERÍA',
    href: '/galeria',
    color: '#80b1b0'
  },
  {
    text: 'CONTÁCTANOS',
    href: '/contacto',
    color: '#80b1b0',
    fixed: true
  },
];

export default options;
