import React, { Component } from 'react';
import { Link } from "react-router-dom";

import HomeMenu from './homeMenu';
import MobileFooter from './mobileFooter';

class Home extends Component {
  constructor(props){
    super(props);
  }
  componentWillMount(){
    const props = this.props;
    const client = props.client;
    const headerType = client.headerType;
    props.showMenuRight(false);
  }
  render(){
    const props = this.props;
    const client = props.client;
    const headerType = client.headerType;
    return(
      <div
        id='Home'
        className={headerType}
        >
        <div className='hero'>
          {
          headerType !== 'phone' && <Link to='/contacto'>
            <div className='contactLink'>CONTÁCTANOS</div>
          </Link>
          }
        </div>
        {headerType !== 'phone' ? <HomeMenu setActive={props.setActive} /> : <MobileFooter />}
      </div>
    );
  }
}

export default Home;
