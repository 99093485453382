import React, { Component } from 'react';
import { Route, Link } from "react-router-dom";

import options from './menuObject.js';

import LOGO from '../../images/logo.png';

class MenuRight extends Component {
  constructor(props){
    super(props);

    this.state = {
      //active: null,
      enter: null,
      open: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
  }
  handleOpen(el){
    const state = this.state;
    if(el) this.scrollToElement(el);
    this.setState({
      open: !state.open
    });
  }
  render() {
    const props = this.props;
    const state = this.state;
    const client = props.client;
    const headerType = client.headerType;
    const active = props.active;

    var menu = map(options, (val, key) => {

      return <Link
        className={`menuItem ${active == key || val.fixed ? 'active' : ''} ${state.enter == key ? 'enter' : ''}`}
        to={val.href} key={key}
        onMouseEnter={() => {
          this.setState({enter: key});
        }}
        onMouseLeave={() => {
          this.setState({enter: null});
        }}
        onClick={() => {
          //this.setState({active: key});
          props.setActive(key);
          this.handleOpen();
        }}
        >
        <div className="background"
          style={{
            backgroundColor: val.color,
            color: val.color
          }}>
          {val.text}
        </div>
        <div className='link'>{val.text}</div>
      </Link>
    });

    return (
      <div
        id="MenuRight"
        className={headerType}
        >
        {headerType === 'phone' && <div
          id="nav-icon-container"
        >
          <div
            id='nav-icon'
            className={ `${state.open}` }
            onClick={ () => {
              this.handleOpen();
            }}
          >
            <span className='burger-icon top'></span>
            <span className='burger-icon mid'></span>
            <span className='burger-icon bottom'></span>
          </div>
        </div>}
        <div
          className={ `MenuRightContent ${state.open}`}
        >
          {headerType !== 'phone' && <div className="logo">
            <Link to='/'><img src={LOGO} /></Link>
          </div>
          }
          <div className="container">
            {menu}
          </div>
        </div>
      </div>
    );
  }
}

export default MenuRight;
