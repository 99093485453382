window.loadScript = function(url, callback){
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.id = 'temporalData';

	if (script.readyState){
		script.onreadystatechange = function(){
			if (script.readyState == 'loaded' || script.readyState == 'complete'){
				script.onreadystatechange = null;
				callback();
			}
		};
	}
	else{
		script.onload = function(){
			callback();
		};
	}

	script.src = url;
	document.getElementsByTagName('head')[0].appendChild(script);
};
