import React, { Component } from "react";
import { Route, Link } from "react-router-dom";

import LOGO from "../../images/logo.png";
import WA from "../../images/contact/waicon.png";
import LOADING from "../../images/contact/loading.gif";

var url =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyCin8ND1bV5tXAOdHyagn73bsamPHl70oo&libraries=places";
var googleMaps,
  googleMapsMap,
  googlemapsHorizontalBar,
  googleMapsBottomLeft,
  googleMapsSearchBox;

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mail: "",
      phone: "",
      desc: "",
      loading: false,
      message: "",
      doneColor: "#ffffff",
    };

    this.handleChange = this.handleChange.bind(this);
    this.loadMap = this.loadMap.bind(this);
    this.delayLoad = this.delayLoad.bind(this);
    this.windowOpen = this.windowOpen.bind(this);
    this.enviar = this.enviar.bind(this);
  }
  componentWillMount() {
    this.props.showMenuRight(true);
  }
  componentDidMount() {
    let google = window.google || undefined;

    if (!google && !window.googleinit) {
      window.googleinit = true;
      loadScript(url, this.loadMap);
    } else this.delayLoad();

    setTimeout(() => {
      this.setState({ init: true });
    }, 500);
  }
  handleChange = (event) => {
    const state = this.state;
    var name = event.target.name;
    var value = event.target.value;
    if (name === "phone") {
      value = value.length > 10 ? state.phone : value;
    }
    this.setState({
      [name]: value,
      message: "",
    });
  };
  delayLoad() {
    let google = window.google || undefined;

    if (google) return this.loadMap();
    clearTimeout(this.timer), (this.timer = null);
    this.timer = setTimeout(this.delayLoad, 100);
  }
  loadMap() {
    const props = this.props;
    googleMaps = googleMaps || google.maps;
    googleMapsMap = googleMapsMap || googleMaps.Map;
    googlemapsHorizontalBar =
      googlemapsHorizontalBar || googleMaps.MapTypeControlStyle.HORIZONTAL_BAR;
    googleMapsBottomLeft =
      googleMapsBottomLeft || googleMaps.ControlPosition.BOTTOM_LEFT;
    const mapElement = document.getElementById("GoogleMap");
    const position = { lat: 19.5464448, lng: -99.3206549 };

    var gmap = new googleMapsMap(mapElement, {
      center: position,
      zoom: 16,
      gmapTypeId: "roadmap",
      mapTypeControlOptions: {
        style: googlemapsHorizontalBar,
        position: googleMapsBottomLeft,
      },
    });

    var contentString =
      '<div class="mapContent">' +
      '<div class="salonName">Festeggia</div>' +
      '<div class="salonAddress">1a. Cerrada de Espíritu Santo S/N Col. Rancho Blanco,</div>' +
      '<div class="salonAddress">Zona Esmeralda, Atizapán de Zaragoza, Edo. de México, México.</div>' +
      "</div>";

    var infowindow = new google.maps.InfoWindow({
      content: contentString,
      maxWidth: 300,
    });

    var marker = new google.maps.Marker({
      map: gmap,
      animation: google.maps.Animation.DROP,
      position: position,
    });

    marker.addListener("click", function() {
      infowindow.open(gmap, marker);
    });
  }
  windowOpen() {
    window.open(
      "https://api.whatsapp.com/send?phone=+5215535047623&text=hola,%20me%20gustaría%20recibir%20informes%20sobre%20su%20salón"
    );
  }
  enviar() {
    const state = this.state;
    var name = state.name.trim();
    var mail = state.mail.trim();
    var phone = state.phone.trim();
    var desc = state.desc.trim();
    var validEmail = false;
    var emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (emailRegex.test(mail)) {
      validEmail = true;
    }

    if (name === "" || mail === "" || phone === "") {
      this.setState({
        message: "por favor llena todos los campos marcados con un *",
        doneColor: "#b1122f",
      });
    } else if (!validEmail) {
      this.setState({
        message: "Ingresa una dirección de email válida.",
        doneColor: "#b1122f",
      });
    } else if (phone.length < 10) {
      this.setState({
        message: "Ingresa una teléfono de 10 dígitos.",
        doneColor: "#b1122f",
      });
    } else {
      this.setState({ loading: true });

      http()
        .method("POST")
        .url("https://www.festeggia.com.mx/php/mail/post.php")
        .data({
          option: 1,
          name,
          mail,
          phone,
          desc,
        })
        .on("success", (data) => {
          if (data && data.ok === 1) {
            this.setState({
              name: "",
              mail: "",
              phone: "",
              desc: "",
              loading: false,
              message:
                "Gracias por contactarnos, en breve alguien de nuestro equipo se pondrá en contacto contigo.",
              doneColor: "#ffffff",
            });
            //console.log('la info se ha enviado correctamente');
          } else {
            console.log(data);
            this.setState({
              loading: false,
              message: "No se pudo enviar tu mensaje, intentalo de nuevo",
              doneColor: "#b1122f",
            });
            console.log("No se pudo enviar tu mensaje, intentalo de nuevo");
          }
        })
        .on("error", function(error) {
          this.setState({
            loading: false,
            message: "Hubo un problema, intentalo de nuevo",
            textColor: "#b1122f",
          });
          console.log(error);
          console.log("Hubo un problema, intentalo de nuevo");
        })
        .go();
    }
  }
  render() {
    const props = this.props;
    const state = this.state;
    const client = props.client;
    const headerType = client.headerType;

    return (
      <div id="Contact" className={headerType}>
        <div className="mainContent">
          {headerType === "phone" && (
            <div className="headerContent">
              <div className="logo">
                <Link to="/">
                  <img src={LOGO} />
                </Link>
              </div>
              <div className="sectionName">CONTACTO</div>
            </div>
          )}
          <div className="contactContent">
            <div className="column twoContent">
              <div className="mapContainer">
                <div className="map">
                  <div className="addressContainer">
                    <div className="mapTitle">ESTAMOS UBICADOS EN...</div>
                    <div className="address">
                      1a. Cerrada de Espíritu Santo S/N Col. Rancho Blanco,
                    </div>
                    <div className="address">
                      Zona Esmeralda, Atizapán de Zaragoza, Edo. de México,
                      México.
                    </div>
                  </div>
                  <div className="googleMap" id="GoogleMap" />
                </div>
              </div>
              <div className="dataContainer">
                <div className="data">
                  <div className="dataTitle">LLÁMANOS O ESCRÍBENOS A:</div>
                  <div className="dataInfo">55 58 08 19 49</div>
                  <div className="dataInfo">informesfesteggia@gmail.com</div>
                  <div className="wacontainer" onClick={this.windowOpen}>
                    <div className="waicon">
                      <img src={WA} alt="whatsapp" />
                    </div>
                    <div className="watext">
                      <div className="text">DA CLICK AQUI PARA</div>
                      <div className="text">ENVIARNOS UN MENSAJE</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="formContainer">
                <div className="formTitle">
                  DÉJANOS UN MENSAJE Y NOS COMUNICAREMOS CONTIGO A LA BREVEDAD
                  POSIBLE
                </div>
                <div className="formItem">
                  <div className="title">* NOMBRE:</div>
                  <input
                    type="text"
                    name="name"
                    onChange={this.handleChange}
                    value={state.name}
                  />
                </div>
                <div className="formItem">
                  <div className="title">* CORREO:</div>
                  <input
                    type="text"
                    name="mail"
                    onChange={this.handleChange}
                    value={state.mail}
                  />
                </div>
                <div className="formItem">
                  <div className="title">* TELÉFONO:</div>
                  <input
                    type="number"
                    name="phone"
                    onChange={this.handleChange}
                    value={state.phone}
                  />
                </div>
                <div className="formItem">
                  <div className="title">MENSAJE:</div>
                  <textarea
                    name="desc"
                    onChange={this.handleChange}
                    value={state.desc}
                  />
                </div>
                <div className="send">
                  <div
                    className="doneMessage"
                    style={{ color: state.doneColor }}
                  >
                    {state.message}
                  </div>
                  {state.loading ? (
                    <img src={LOADING} />
                  ) : (
                    <div className="sendText" onClick={this.enviar}>
                      {" "}
                      ENVIAR{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
