import React, { Component } from 'react';

import LOGO from '../../images/logo.png';

class MobileFooter extends Component {
  constructor(props){
    super(props);
  }
  render(){
    return(
      <div className='mobileFooter'>
        <div className="center">
          <img src={LOGO}></img>
        </div>
      </div>
    );
  }
}

export default MobileFooter;
