import React, { Component } from 'react';
import { Link } from "react-router-dom";

import LOGO from '../../images/logo.png';
import NOSOTROSCOLOR from '../../images/home/nosotros-color.jpg';
import MINIMALISTACOLOR from '../../images/home/minimal-color.jpg';
import INFANTILCOLOR from '../../images/home/infantil-color.jpg';
import SERVICIOSCOLOR from '../../images/home/servicios-color.jpg';
import BANQUETESCOLOR from '../../images/home/banquetes-color.jpg';
import GALERIACOLOR from '../../images/home/galeria-color.jpg';

class HomeMenu extends Component {
  constructor(props){
    super(props);
  }
  render(){
    const props = this.props;

    return(
      <div className='homeMenu'>
        <Link
          className='menuItem'
          to="/nosotros"
          onMouseEnter={() => { props.setActive(0) } }
          >
          <div
            className='colorImage'
            style={{
              backgroundImage: `url(${NOSOTROSCOLOR})`
            }}
          ></div>
          <div className='scaleImage'></div>
         <div className='title'>NOSOTROS</div>
        </Link>
        <Link
          className='menuItem'
          to="/minimalista"
          onMouseEnter={() => { props.setActive(1) } }
        >
          <div
            className='colorImage'
            style={{
              backgroundImage: `url(${MINIMALISTACOLOR})`
            }}
          ></div>
          <div className='scaleImage'></div>
          <div className='title'>MINIMALISTA</div>
        </Link>
        <Link
          className='menuItem'
          to="/infantil"
          onMouseEnter={() => { props.setActive(2) } }
        >
          <div
            className='colorImage'
            style={{
              backgroundImage: `url(${INFANTILCOLOR})`
            }}
          ></div>
          <div className='scaleImage'></div>
          <div className='title'>INFANTIL</div>
        </Link>
        <div className='menuCenter'>
          <img src={LOGO} />
        </div>
        <Link
          className='menuItem'
          to="/servicios"
          onMouseEnter={() => { props.setActive(3) } }
        >
          <div
            className='colorImage'
            style={{
              backgroundImage: `url(${SERVICIOSCOLOR})`
            }}
          ></div>
          <div className='scaleImage'></div>
        <div className='title'>SERVICIOS</div>
        </Link>
        <Link
          className='menuItem'
          to="/banquetes"
          onMouseEnter={() => { props.setActive(4) } }
        >
          <div
            className='colorImage'
            style={{
              backgroundImage: `url(${BANQUETESCOLOR})`
            }}
          ></div>
          <div className='scaleImage'></div>
        <div className='title'>BANQUETES</div>
        </Link>
        <Link
          className='menuItem'
          to="/galeria"
          onMouseEnter={() => { props.setActive(5) } }
        >
          <div
            className='colorImage'
            style={{
              backgroundImage: `url(${GALERIACOLOR})`
            }}
          ></div>
          <div className='scaleImage'></div>
        <div className='title'>GALERÍA</div>
        </Link>
      </div>
    );
  }
}

export default HomeMenu;
