import React, { Component } from 'react';
import { Route, Link } from "react-router-dom";

import LOGO from '../../images/logo.png';
import FEGOS from '../../images/childish/FEGOS.png';

import IMAGE1 from '../../images/childish/childish1.jpg';
import IMAGE2 from '../../images/childish/childish2.jpg';
import IMAGE3 from '../../images/childish/childish3.jpg';
import IMAGE4 from '../../images/childish/childish4.jpg';
import IMAGE5 from '../../images/childish/childish5.jpg';
//import IMAGE6 from '../../images/childish/childish6.jpg';
import IMAGE7 from '../../images/childish/childish7.jpg';
import IMAGE8 from '../../images/childish/childish8.jpg';
import IMAGE9 from '../../images/childish/childish9.jpg';
import IMAGE10 from '../../images/childish/childish10.jpg';
import IMAGE11 from '../../images/childish/childish11.jpg';
import IMAGE12 from '../../images/childish/childish12.jpg';
import IMAGE13 from '../../images/childish/childish13.jpg';
import IMAGE14 from '../../images/childish/childish14.jpg';
import IMAGE15 from '../../images/childish/childish15.jpg';
import IMAGE16 from '../../images/childish/childish16.jpg';
import IMAGE17 from '../../images/childish/childish17.jpg';
import IMAGE18 from '../../images/childish/childish18.jpg';
import IMAGE19 from '../../images/childish/childish19.jpg';

const arrayImages = [
  IMAGE1,
  IMAGE2,
  IMAGE3,
  IMAGE4,
  IMAGE5,
  //IMAGE6,
  IMAGE7,
  IMAGE8,
  IMAGE9,
  IMAGE10,
  IMAGE11,
  IMAGE12,
  IMAGE13,
  IMAGE14,
  IMAGE15,
  IMAGE16,
  IMAGE17,
  IMAGE18,
  IMAGE19,
]

var imageInterval;

class Childish extends Component {
  constructor(props){
    super(props);
    this.state = {
      showImage: 0
    }

    this.setSliderImage = this.setSliderImage.bind(this);
  }
  componentWillMount(){
    this.props.showMenuRight(true);
  }
  componentDidMount(){
    imageInterval = setInterval(this.setSliderImage, 3000);
  }
  componentWillUnmount(){
    clearInterval(imageInterval);
  }
  setSliderImage(){
    const state = this.state;
    if(state.showImage < arrayImages.length - 1) this.setState({showImage: state.showImage + 1});
    else this.setState({showImage: 0});
  }
  render(){
    const props = this.props;
    const state = this.state;
    const client = props.client;
    const headerType = client.headerType;

    var images = map(arrayImages, (val, key) => {
      return <div
        className={`sliderImage ${state.showImage === key ? 'show' : ''}`}
        style={{
          backgroundImage: `url(${val})`
        }}
        key={key}
      ></div>
    });

    return(
      <div
        id="Childish"
        className={headerType}
      >
        <div className="mainContent">
          {
          headerType === 'phone' && <div className="headerContent">
            <div className="logo">
              <Link to='/'>
                <img src={LOGO} />
              </Link>
            </div>
            <div className="sectionName">INFANTIL</div>
          </div>
          }
          <div className="sliderContent">
            {images}
          </div>
          <div className="descriptionContent">
            <div className="descriptionText">
              <div className="textContent oneColumnText">
                <div className="fegos"><img src={FEGOS} /></div>
                Ven  y realiza tus eventos, tenemos diferentes áreas para la diversión y
                entretenimiento para los pequeños, donde encontrarán áreas al aire libre,
                dinámicas como cancha de fútbol y un gran muro para escalar, además de rapel,
                tirolesa y Playground grande.
              </div>
              <div className="textContent twoColumnsText">
                <div className="column">
                  <div className="paragraph">
                     Ofrecemos:
                     <ul>
                       <li>– Resbaladilla gigante con led’s</li>
                       <li>– Juego tipo playground multinivel</li>
                       <li>– Área de bebes tipo playground</li>
                       <li>– Tirolesa</li>
                       <li>– Caballetes</li>
                       <li>- Pared de rapel</li>
                       <li>- Cancha de futbol</li>
                       <li>- Área de piñata</li>
                     </ul>
                  </div>
                </div>
                <div className="column last">
                  <div className="paragraph last">
                    <ul>
                      <li>- Cocinita infantil</li>
                      <li>- Salón de belleza</li>
                      <li>- Música ambiental continúa</li>
                      <li>- Cocina totalmente equipada</li>
                      <li>- Mini súper</li>
                      <li>- Área de carritos</li>
                      <li>- Taller mecánico</li>
                      <li>- Goofy o Mickey parte el pastel contigo</li>
                      <li>- Estacionamiento privado con costo para los invitados</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Childish;
