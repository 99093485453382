const totalImages = 76;

var  galleryItems = [];

for(var i = 1; i <= totalImages; i++){
  galleryItems.push({
    src: require(`../../images/gallery/${i}.jpg`),
    type: 'image'
  });
}

export default galleryItems;
