import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import logo from './logo.svg';
import './sass/App.css';
require('./misc');
import MenuRight from './components/menu/menuRight';
import Home from './components/home';
import About from './components/about';
import Minimalist from './components/minimalist';
import Childish from './components/childish';
import Services from './components/services';
import Banquets from './components/banquets';
import Gallery from './components/gallery';
import Contact from './components/contact';

import masterStore from './stores/masterStore';

var getState = function(){
	return {
		client: masterStore.client()
	};
};

class App extends Component {
  constructor(props){
    super(props);

    this.state = {
			client: masterStore.client(),
			showMenuRight: false,
			active: null
		};

    this.onChange = this.onChange.bind(this);
		this.showMenuRight = this.showMenuRight.bind(this);
		this.setActive = this.setActive.bind(this);
  }
  componentDidMount(){
    masterStore.addChangeListener(this.onChange);
  }
  componentWillUnmount(){
    masterStore.removeChangeListener(this.onChange);
  }
  onChange(){
		this.setState(getState());
	}
	showMenuRight(show){
		this.setState({showMenuRight: show});
	}
	setActive(item){
		this.setState({active: item});
	}
  render() {
		const props = this.props;
    const state = this.state;
    const client = state.client;
    const headerType = client.headerType;

    return (
      <div>
				{ (state.showMenuRight || headerType === 'phone') && <MenuRight client={client} setActive={this.setActive} active={state.active}/> }
        <Route exact path="/" render={(props) => <Home {...props} client={client} showMenuRight= {this.showMenuRight} setActive={this.setActive}/>} />
        <Route path="/nosotros" render={(props) => <About {...props} client={client} showMenuRight= {this.showMenuRight} setActive={this.setActive}/>} />
				<Route path="/minimalista" render={(props) => <Minimalist {...props} client={client} showMenuRight= {this.showMenuRight} setActive={this.setActive}/>} />
				<Route path="/infantil" render={(props) => <Childish {...props} client={client} showMenuRight= {this.showMenuRight} setActive={this.setActive}/>} />
				<Route path="/servicios" render={(props) => <Services {...props} client={client} showMenuRight= {this.showMenuRight} setActive={this.setActive}/>} />
				<Route path="/banquetes" render={(props) => <Banquets {...props} client={client} showMenuRight= {this.showMenuRight} setActive={this.setActive}/>} />
				<Route path="/galeria" render={(props) => <Gallery {...props} client={client} showMenuRight= {this.showMenuRight} setActive={this.setActive}/>} />
				<Route path="/contacto" render={(props) => <Contact {...props} client={client} showMenuRight= {this.showMenuRight} setActive={this.setActive}/>} />

		</div>
    );
  }
}

export default App;
