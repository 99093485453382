import React, { Component } from 'react';
import { Route, Link } from "react-router-dom";

import LOGO from '../../images/logo.png';

import IMAGE1 from '../../images/banquets/banquets1.jpg';
import IMAGE2 from '../../images/banquets/banquets2.jpg';
import IMAGE3 from '../../images/banquets/banquets3.jpg';
import IMAGE4 from '../../images/banquets/banquets4.jpg';
import IMAGE5 from '../../images/banquets/banquets5.jpg';
import IMAGE6 from '../../images/banquets/banquets6.jpg';
import IMAGE7 from '../../images/banquets/banquets7.jpg';
import IMAGE8 from '../../images/banquets/banquets8.jpg';

const arrayImages = [
  IMAGE1,
  IMAGE2,
  IMAGE3,
  IMAGE4,
  IMAGE5,
  IMAGE6,
  IMAGE7,
  IMAGE8,
]

var imageInterval;

class Banquets extends Component {
  constructor(props){
    super(props);
    this.state = {
      showImage: 0
    }

    this.setSliderImage = this.setSliderImage.bind(this);
  }
  componentWillMount(){
    this.props.showMenuRight(true);
  }
  componentDidMount(){
    imageInterval = setInterval(this.setSliderImage, 3000);
  }
  componentWillUnmount(){
    clearInterval(imageInterval);
  }
  setSliderImage(){
    const state = this.state;
    if(state.showImage < arrayImages.length - 1) this.setState({showImage: state.showImage + 1});
    else this.setState({showImage: 0});
  }
  render(){
    const props = this.props;
    const state = this.state;
    const client = props.client;
    const headerType = client.headerType;

    var images = map(arrayImages, (val, key) => {
      return <div
        className={`sliderImage ${state.showImage === key ? 'show' : ''}`}
        style={{
          backgroundImage: `url(${val})`
        }}
        key={key}
      ></div>
    });

    return(
      <div
        id="Banquets"
        className={headerType}
      >
        <div className="mainContent">
          {
          headerType === 'phone' && <div className="headerContent">
            <div className="logo">
              <Link to='/'>
                <img src={LOGO} />
              </Link>
            </div>
            <div className="sectionName">BANQUETES</div>
          </div>
          }
          <div className="sliderContent">
            {images}
          </div>
          <div className="descriptionContent">
            <div className="descriptionText">
              <div className="oneColumnText">
                <div className="paragraph">
                   Avanzadas técnicas del arte culinario forman una mezcla de tradiciones,
                   buen gusto y elegancia; dando como resultado unos platillos selectos y
                   con estilo propio.
                </div>
                <div className="paragraph">
                   Contamos con taquizas, parrilladas, puestos de kermés y mucho más...
                </div>
              </div>
              <div className="rowText">
                <div className="row title">ALGUNOS DE NUESTROS PLATILLOS</div>
                <div className="row last">
                  <div className="threeColumnsText">
                    <div className="column first">
                      <div className="title">Entradas</div>
                      <ul>
                        <li>– Chile ancho relleno de panela en espejo de frijol</li>
                        <li>– Tortelini a los tres quesos</li>
                        <li>– Ensalada Cesar</li>
                      </ul>
                    </div>
                    <div className="column second ">
                      <div className="title">Sopas y cremas</div>
                      <ul>
                        <li>– Crema de chicharrón</li>
                        <li>– Crema de queso con nuez</li>
                        <li>– Crema de cilantro</li>
                      </ul>
                    </div>
                    <div className="column last">
                      <div className="title">Platos fuertes</div>
                      <ul>
                        <li>– Medallón de filete a la pimienta</li>
                        <li>– Pechuga rellena de queso de cabra con nuez en guajillo</li>
                        <li>– Escalopa de ternera con champiñón al vino tinto</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Banquets;
