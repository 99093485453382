'use strict';

import masterStore from '../stores/masterStore';

window.addEventListener('resize', function() {
	if( !client.isMobileAgent ){
		if(window.innerWidth > 1200){
			masterStore.searchBarVisibility = false;
			client.headerType = 'desktop';
		}
		else if( window.innerWidth < 615 ){
			client.headerType = 'phone';
		}
		else{
			client.headerType = 'tablet';
		}
	}

	if(window.innerWidth < window.innerHeight){
		client.orientation = 'portrait';
	}
	else{
		client.orientation = 'landscape';
	}

	masterStore.emitChange();
});
